import authRoutes from './auth';

const routes = [
  {
    path: '/',
    component: () => import('../views/Layout.vue'),
    children: [
      {
        name: 'Home',
        path: '',
        meta: {
          title: 'Overview | Stimper'
        },
        component: () => import('../views/Home.vue'),
      },
      {
        name: 'Settings',
        path: '/settings',
        meta: {
          title: 'Configurações | Stimper'
        },
        component: () => import('../views/Settings.vue'),
      },
      {
        name: 'CashFlow',
        path: '/cashflow',
        meta: {
          title: 'Relatório | Stimper'
        },
        component: () => import('../views/CashFlow.vue'),
      },
      {
        name: 'Income',
        path: '/income',
        meta: {
          title: 'Receitas | Stimper'
        },
        component: () => import('../views/Income.vue'),
      },
      {
        name: 'Import',
        path: '/import/:id?',
        meta: {
          title: 'Importar | Stimper'
        },
        component: () => import('../views/Import.vue'),
      },
      {
        name: 'Premium',
        path: '/premium',
        meta: {
          title: 'Premium | Stimper'
        },
        component: () => import('../views/Premium.vue'),
      },
      {
        name: 'Transfer',
        path: '/transfer',
        meta: {
          title: 'Transferências | Stimper'
        },
        component: () => import('../views/Transfer.vue'),
      },
      {
        name: 'Bill',
        path: '/bill',
        meta: {
          title: 'Contas a Pagar | Stimper'
        },
        component: () => import('../views/Bill.vue'),
      },
      {
        name: 'Billing',
        path: '/billing',
        meta: {
          title: 'Contas Fixas | Stimper'
        },
        component: () => import('../views/Fix.vue'),
      },
      {
        name: 'Subscriptions',
        path: '/subscriptions',
        meta: {
          title: 'Assinaturas | Stimper'
        },
        component: () => import('../views/Subscriptions.vue'),
      },
      {
        name: 'Expense',
        path: '/expense',
        meta: {
          title: 'Despesas | Stimper'
        },
        component: () => import('../views/Expense.vue'),
      },
      {
        name: 'Transactions',
        path: '/transactions',
        meta: {
          title: 'Transações | Stimper'
        },
        component: () => import('../views/Transactions.vue'),
      },
      {
        name: 'Budget',
        path: '/budget',
        meta: {
          title: 'Orçamentos | Stimper'
        },
        component: () => import('../views/Budget.vue'),
      },
      {
        name: 'Goal',
        path: '/goal',
        meta: {
          title: 'Objetivos | Stimper'
        },
        component: () => import('../views/Goal.vue'),
      },
      {
        name: 'Providers',
        path: '/providers',
        meta: {
          title: 'Fornecedores | Stimper'
        },
        component: () => import('../views/Providers.vue'),
      },
      {
        name: 'Clients',
        path: '/clients',
        meta: {
          title: 'Clientes | Stimper'
        },
        component: () => import('../views/Clients.vue'),
      },
      {
        name: 'Account',
        path: '/account/detail/:id',
        meta: {
          title: 'Conta | Stimper'
        },
        component: () => import('../views/AccountDetails.vue'),
      },
      {
        name: 'AccountDetails',
        path: '/account',
        meta: {
          title: 'Contas | Stimper'
        },
        component: () => import('../views/Account.vue'),
      },
      {
        name: 'Card',
        path: '/card',
        meta: {
          title: 'Cartões de Crédito | Stimper'
        },
        component: () => import('../views/Card.vue'),
      },
      {
        name: 'CardDetail',
        path: '/card/detail/:id',
        meta: {
          title: 'Detalhe do Cartão | Stimper'
        },
        component: () => import('../views/CardDetail.vue'),
      },
      {
        name: 'Forbidden',
        path: '403',
        component: () => import('../views/errors/403.vue'),
      },
    ],
  },
  ...authRoutes,
];

export default routes;
