<template>
  <div class="toast" :class="classes">
    <div class="toast-progress" style="width: 0%;"></div>
    <button type="button" class="toast-close-button" role="button" @click="hide">×</button>
    <div class="toast-title">{{ title }}</div>
    <div class="toast-message">{{ content }}</div>
  </div>
</template>
<script>
import Vue from 'vue';
import Swal from 'sweetalert2';

export default Vue.extend({
  props: ['title', 'content', 'type'],
  data() {
    return {
      isMounted: false,
      visible: false,
      Toast: {},
    };
  },
  computed: {
    classes() {
      return [
        this.type,
        {
          show: this.visible,
        },
      ];
    },
  },
  destroyed() {
    // Make sure we not in document any more
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
  },
  mounted() {
    this.isMounted = true;
    this.$nextTick(() => {
      setTimeout(() => {
        this.show();
      }, 16);
    });
    this.Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });
  },
  methods: {
    show() {
      this.Toast.fire({
        icon: this.type,
        title: this.content,
      });
      /*if (!this.visible) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.visible = true;
            setTimeout(this.hide, 4000);
          }, 150);
        });
      }*/
    },
    hide() {
      const self = this;
      this.Toast.dismiss();
      if (self.visible) {
        self.visible = false;
        self.$nextTick(() => {
          self.$nextTick(() => {
            setTimeout(() => {
              self.$destroy();
            }, 150);
          });
        });
      }
    },
  },
});
</script>
<style scoped lang="scss">

</style>
