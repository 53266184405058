import parseJwt from '@/plugins/functions';
import AuthService from '../services/auth.service';

const apiToken = localStorage.getItem('stimper_token');

const auth = {
  namespaced: true,
  state: {
    token: apiToken,
    user: JSON.parse(localStorage.getItem('stimper_user')),
  },
  actions: {
    login({ commit }, data) {
      return AuthService.login(data).then(
        (resp) => {
          console.log(resp.data);
          commit('loginSuccess', resp.data);
          return Promise.resolve(resp.data);
        },
        (error) => {
          commit('loginFailure');
          return Promise.reject(error.response.data);
        },
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    register({ commit }, data) {
      return AuthService.register(data).then(
        (token) => {
          commit('registerSuccess', token);
          return Promise.resolve(token);
        },
        (error) => {
          commit('registerFailure');
          return Promise.reject(error);
        },
      );
    },
    reset({ commit }, data) {
      console.log(commit);
      return AuthService.reset(data).then(
        () => Promise.resolve(),
        (error) => Promise.reject(error),
      );
    },
    resetVerification({ commit }, token) {
      console.log(commit);
      return AuthService.resetVerification(token).then(
        (response) => Promise.resolve(response),
        (error) => Promise.reject(error),
      );
    },
    resetPassword({ commit }, data) {
      console.log(commit);
      return AuthService.resetPassword(data).then(
        (response) => Promise.resolve(response),
        (error) => Promise.reject(error),
      );
    },
    refresh({ commit }) {
      return AuthService.refresh().then(
        (token) => {
          commit('refreshToken', token);
          return Promise.resolve(token);
        },
        (error) => {
          AuthService.logout();
          commit('logout');
          return Promise.reject(error);
        },
      );
    },

  },
  mutations: {
    loginSuccess(state, data) {
      // state.status.loggedIn = true;
      state.token = data.token;
      state.user = data.user;
    },
    loginFailure(state) {
      state.token = null;
      state.user = null;
    },
    logout(state) {
      state.token = null;
      state.user = null;
    },
    registerSuccess(state, token) {
      state.token = token;
      state.user = parseJwt(token).sub;
    },
    registerFailure(state) {
      state.token = null;
      state.user = null;
    },
    refreshToken(state, token) {
      state.token = token;
    },
  },
};

export default auth;
