import Vue from 'vue';
import VueRouter from 'vue-router';
import appRoutes from './app';
import unsubscribeRoutes from './unsubscribe';

Vue.use(VueRouter);

console.log([appRoutes, window.location.hostname]);

let routes;
let isUnsubscribe;

switch (window.location.hostname) {
  case 'unsubscribe.yupchat.net':
  case '127.0.0.1':
    routes = unsubscribeRoutes;
    isUnsubscribe = true;
    break;
  default:
    routes = appRoutes;
    isUnsubscribe = false;
}
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // const publicPages = ['/login', '/register', '/reset-password', '/reset-verification/:token'];
  const authRequired = !to.meta.guest && !to.meta.both;
  const bothAccess = to.meta.both;
  const loggedIn = localStorage.getItem('stimper_token');

  if (to.meta.title) document.title = to.meta.title;

  if (isUnsubscribe) {
    next();
  } else if (authRequired) {
    // trying to access a restricted page + not logged in
    // redirect to login page
    if (loggedIn) {
      next();
    } else {
      next('/login');
    }
  } else if (!loggedIn || bothAccess) {
    next();
  } else {
    next('');
  }
});

export default router;
