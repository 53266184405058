const routes = [
  {
    path: '/',
    component: () => import('../views/Auth.vue'),
    children: [
      {
        path: '/login',
        name: 'Login',
        component: () => import('../views/auth/Login.vue'),
        meta: {
          guest: true,
          title: 'Login | Stimper'
        },
      },
      {
        path: '/register',
        name: 'Register',
        component: () => import('../views/auth/Register.vue'),
        meta: {
          guest: true,
          title: 'Cadastro | Stimper'
        },
      },
      {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('../views/auth/ResetPassword.vue'),
        meta: {
          guest: true,
          title: 'Recuperar senha | Stimper'
        },
      },
      {
        path: '/reset-verification/:token',
        name: 'ResetVerification',
        component: () => import('../views/auth/ResetVerification.vue'),
        meta: {
          guest: true,
          title: 'Recuperar senha | Stimper'
        },
      },
    ],
  },
];

export default routes;
