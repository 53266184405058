<template>
  <div >
    <div ref="slot" id="toast-container" class="toast-top-right">
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import Toast from './Toast.vue';

export default Vue.extend({
  data() {
    return {
      isMounted: false,
      toasts: [],
    };
  },
  computed: {
    classes() {
      return ['toaster'];
    },
  },
  methods: {
    show(options) {
      /*if (!this.isMounted) {
        this.$mount();
        document.body.appendChild(this.$el);
      }*/
      const toast = new Toast({
        propsData: {
          title: options.title,
          content: options.content,
          type: options.type,
        },
      });
      toast.$mount();
      //this.$refs.slot.prepend(toast.$el);
    },
  },
  mounted() {
    this.isMounted = true;
  },
});
</script>
<style scoped lang="scss">

</style>
