import axios from '@/plugins/axios';

class AuthService {
  login(user) {
    this.user = user;

    return axios().post('auth/login', user).then((response) => {
      console.log(response);
      if (response.data.data.token) {
        localStorage.setItem('stimper_user', JSON.stringify(response.data.data.user));
        localStorage.setItem('stimper_token', response.data.data.token);
      }
      return response.data;
    });
  }

  logout() {
    this.a = 'a';
    localStorage.removeItem('stimper_token');
  }

  register(data) {
    this.data = data;
    return axios().post('auth/signup', data).then((response) => {
      if (response.data.data.token) {
        localStorage.setItem('stimper_user', JSON.stringify(response.data.data.user));
        localStorage.setItem('stimper_token', response.data.data.token);
      }
      return response.data.data.token;
    });
  }

  reset(data) {
    this.data = data;
    return axios().post('auth/forgot', data);
  }

  resetVerification(data) {
    this.data = data;
    return axios().post('auth/pass-reset', data);
  }

  resetPassword(data) {
    this.data = data;
    return axios().post('auth/reset-verification', data)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data.message));
  }

  refresh() {
    this.a = 'a';
    return axios().get('auth/token').then((response) => {
      if (response.data.token) {
        localStorage.setItem('stimper_token', JSON.stringify(response.data.token));
      }
      return response.data.token;
    });
  }
}

export default new AuthService();
