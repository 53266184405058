import axios from 'axios';
import qs from 'qs';

export default (vApi = 'v1') => {
  let axiosApi = {};
  switch (vApi) {
    case 'v1':
      axiosApi = axios.create({
        baseURL: process.env.VUE_APP_API_URL,
        paramsSerializer: (params) => qs.stringify(params),
      });
      break;
    case 'v2':
      axiosApi = axios.create({
        baseURL: process.env.VUE_APP_API_V2_URL,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      });
      break;
    case 'bucket':
      axiosApi = axios.create({
        baseURL: process.env.VUE_APP_API_BUCKET_URL,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
      });
      break;
    default:
      break;
  }

  axiosApi.interceptors.request.use(
    (config) => {
      const newConfig = config;
      const token = localStorage.getItem('stimper_token');
      if (token) {
        newConfig.headers.Authorization = `${token}`;
      }
      newConfig.headers['Content-Type'] = 'application/json';
      return newConfig;
    },
    (error) => {
      console.log(error);
      Promise.reject(error);
    },
  );

  return axiosApi;
};
