const crop = {
  namespaced: true,
  state: {
    crop: {
      minWidth: 200,
      minHeight: 200,
      name: 0,
      minSize: true,
      infoSize: '',
    },
  },
  actions: {
    config({ commit }, data) {
      commit('initCrop', data);
    },
  },
  mutations: {
    initCrop(state, param) {
      this.state.crop = param;
      console.log(param);
      console.log(this.state);
    },
  },
};

export default crop;
