import ModalDirective from './ModalDirective';

const ModalPlugin = {
  install(Vue) {
    const vm = Vue;
    vm.directive('modal', ModalDirective);
  },
};

export default ModalPlugin;
